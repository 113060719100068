<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="show"
			confirm-button-text="Update"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Update Command
			</template>

			<var-skeleton :rows="7" :loading="loading">
				<var-form ref="form">
					<var-input
						placeholder="Description"
						:rules="[v => !!v || 'Command description cannot be empty']"
						v-model="formData.description"
						class="mb-3"
					/>
					<var-input
						placeholder="Body"
						:rules="[v => !!v || 'Command body cannot be empty']"
						textarea
						v-model="formData.text"
						class="mb-3"
					/>
					<var-select
						placeholder="Duration"
						:rules="[v => !!v || 'You must select a duration']"
						v-model="formData.duration_id"
						class="mb-3"
					>
						<var-option v-for="duration in durations" :label="duration.text" :value="duration.id"  />
					</var-select>
					<var-select
						placeholder="Execution state"
						:rules="[v => v.length >= 1 || 'You must select an execution state']"
						v-model="formData.execution_state"
						class="mb-3"
					>
						<var-option v-for="(executionState, key) in executionStates" :label="executionState" :value="key"  />
					</var-select>
					<var-select
						placeholder="Frequency"
						:rules="[v => v.length >= 1 || 'You must select a frequency']"
						v-model="formData.frequency"
						class="mb-3"
					>
						<var-option v-for="(frequency, key) in frequencies" :label="frequency" :value="key"  />
					</var-select>
					<var-select
						placeholder="Servers"
						multiple
						:rules="[v => v.length >= 1 || 'You must select at least one server']"
						v-model="formData.server_ids"
						class="mb-3"
					>
						<var-option v-for="server in servers" :label="server.name" :value="server.id"  />
					</var-select>
				</var-form>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<script>

import {reactive, ref} from "vue";
import request from "../request";

export default {
	name: "UpdateCommandModal",
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	watch: {
		show(newVal, oldVal) {
			if (newVal && !this.servers) {
				this.getCommand()
			}
		}
	},
	create() {
		this.getCommand()
	},
	setup() {
		const formData = reactive({
			duration_id: null,
			description: null,
			text: null,
			execution_state: null,
			frequency: null,
			server_ids: []
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			id: null,
			show: false,
			loading: false,
			command: null,
			durations: null,
			executionStates: null,
			frequencies: null,
			servers: null,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.form.validate().then(validated => {
						if (!validated) {
							return
						}

						this.loading = true

						request.put(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/commands/' + this.id, this.formData)
							.then(data => {
								this.loading = false
								done()
							})
							.catch(error => {
								alert(error.response.data.message)
							})
					})
				} else {
					done()
				}
			},
		}
	},
	methods: {
		getCommand() {
			this.loading = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/commands/' + this.id)
				.then(data => {
					this.servers = data.servers
					this.durations = data.durations
					this.executionStates = data.execution_states
					this.frequencies = data.frequencies

					this.command = data.command
					this.formData.duration_id = this.command.duration_id
					this.formData.description = this.command.description
					this.formData.execution_state = this.command.execution_state
					this.formData.frequency = this.command.frequency
					this.formData.text = this.command.text
					this.formData.server_ids = this.command.server_ids

					this.loading = false
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>
<template>
	<data-table
		:data-source="dataSource"
		:header-mapping="headerMapping"
		:select-at-row="selectAtRow"
		action-button-name="Create"
		secondary-button-name="Add from existing"
		@onActionClick="$emit('onCreateClick')"
		@onSecondaryButtonClick="$emit('onAddClick')"
	/>
</template>

<script>
import request from "../request";
import DataTable from "./DataTable"

export default {
	name: "ServerTab",
	components: {DataTable},
	emits: ['onCreateClick', 'onAddClick'],
	props: {
		projectId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.projectId + '/servers?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			headerMapping: {
				name: "Name",
				host_name: 'Host Name',
				port: 'Port',
				username: 'Username',
				authorization_type: 'Authorization Type',
				default_branch: 'Branch',
				automatic_deployment: "Auto-Deploy"
			},
			selectAtRow: id => {
				this.$router.push({
					name: 'ServerDetails',
					params: {
						project_id: this.projectId,
						server_id: id
					}
				})
			}
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
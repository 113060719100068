<template>
	<var-skeleton :rows="10" :loading="loading">
		<div class="commands-content p-4">
			<var-cell title="Before Changes" desc="Executed immediately once connected to the server."/>
			<div v-if="!beforeCommands.length" class="commands-placeholder">
				<h4>This project doesn't have any commands for Before Changes yet!</h4>
				<var-button type="primary" @click="$emit('onCreateClick', 'before')">Create one</var-button>
			</div>
			<div v-else>
				<command-cell
					v-for="beforeCommand in beforeCommands"
					:name="beforeCommand.description"
					:servers="beforeCommand.linked_servers"
					@onEditClick="editCommand(beforeCommand.id)"
					@onDeleteClick="$emit('onCommandDeleteClick', beforeCommand.id)"
				/>
			</div>
			<var-cell title="After Changes"
			          desc="Executed at the end of a deployment after files have been uploaded/removed."/>
			<div v-if="!afterCommands.length" class="commands-placeholder">
				<h4>This project doesn't have any commands for After Changes yet!</h4>
				<var-button type="primary" @click="$emit('onCreateClick', 'after')">Create one</var-button>
			</div>
			<div v-else>
				<draggable
					v-model="afterCommands"
					group="people"
					@start="dragging = true"
					@end="dragging = false"
					tag="transition-group"
					:component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
					item-key="id"
					@change="onChange"
				>
					<template #item="{element}">
						<command-cell
							:name="element.description"
							:servers="element.linked_servers"
							@onEditClick="editCommand(element.id)"
							@onDeleteClick="$emit('onCommandDeleteClick', element.id)"
						/>
					</template>
				</draggable>


				<var-button type="primary" @click="createCommand">Create</var-button>
			</div>
		</div>
	</var-skeleton>
</template>

<script>
import request from "../request"
import DataTable from "./DataTable"
import CommandCell from "./CommandCell"
import draggable from 'vuedraggable'

export default {
	name: "SSHCommandTab",
	components: {
		CommandCell,
		DataTable,
		draggable
	},
	emits: ['onCreateClick', 'onCommandEditClick', 'onCommandDeleteClick'],
	props: {
		projectId: {
			type: Number,
			required: true
		}
	},
	created() {
		this.getCommands()
	},
	data() {
		return {
			dragging: false,
			loading: false,
			showDeleteModal: false,
			deleteId: null,
			beforeCommands: [],
			afterCommands: [],
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.projectId + '/commands?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			headerMapping: {
				description: 'Description',
				linkedServers: 'Servers',
				execution_state: 'Execution State',
				frequency: 'Frequency',
				"duration.text": 'Duration',
				created_at: 'Created At'
			},
			selectAtRow: id => {
				this.$router.push({
					name: 'ProjectDetails',
					params: {
						id
					}
				})
			}
		}
	},
	methods: {
		getCommands() {
			this.loading = true

			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.projectId + '/commands')
				.then(data => {
					this.loading = false
					this.beforeCommands = data.before
					this.afterCommands = data.after
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		deleteCommand() {
			this.loading = true

			request.delete(process.env.VUE_APP_BASE_URL + '/projects/' + this.projectId + '/commands/' + this.deleteId)
				.then(data => {
					this.beforeCommands = data.before
					this.afterCommands = data.after
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		createCommand() {
			this.$router.push({
				name: 'CreateCommand',
				params: {
					id: this.projectId
				}
			})
		},
		editCommand(commandId) {
			this.$router.push({
				name: 'UpdateCommand',
				params: {
					project_id: this.projectId,
					command_id: commandId
				}
			})
		},
		onChange() {
			const reordered = this.afterCommands.map(((obj, index) => {
				return {
					id: obj.id,
					index: index
				}
			}))

			request.put(process.env.VUE_APP_BASE_URL + `/projects/${this.projectId}/reorder/commands`, {
				data: reordered
			})
				.then(data => {
					console.log(data)
				})
				.catch(error => {
					console.log(error)
				})
		},
		refresh() {
			this.getCommands()
		}
	}
}
</script>

<style scoped>

.commands-placeholder {
	height: 120px;
	border: 1px dashed gray;
	text-align: center;
	padding-top: 1.5rem;
}

.commands-content {
	background-color: var(--table-background);
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

</style>
<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px', '--dialog-message-line-height': '22px' }">
		<var-dialog
			v-model:show="show"
			confirm-button-text="Create"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Create Server
			</template>

			<var-skeleton :rows="7" :loading="loadingBranches || loading">
				<var-form ref="form">
					<var-space direction="column" :size="[10, 0]">
						<var-row :gutter="20">
							<var-col :span="13">
								<var-input
									placeholder="Server name"
									:rules="[v => !!v || 'Server name cannot be empty']"
									v-model="formData.name"
								/>
							</var-col>

							<var-col :span="11">
								<var-select
									placeholder="Authorization type"
									:rules="[v => v.length >= 1 || 'Authorization type cannot be empty']"
									v-model="formData.authorization_type"
								>
									<var-option label="SSH Key" value="ssh_key"  />
									<var-option label="Password" value="password"  />
								</var-select>
							</var-col>
						</var-row>

						<var-row :gutter="20">
							<var-col :span="16">
								<var-input
									placeholder="Host name"
									:rules="[v => !!v || 'Host name cannot be empty']"
									v-model="formData.host_name"
								/>
							</var-col>
							<var-col :span="8">
								<var-input
									placeholder="Port"
									:rules="[v => !!v || 'Port cannot be empty']"
									v-model="formData.port"
								/>
							</var-col>
						</var-row>
						<var-row :gutter="formData.authorization_type === 'password' ? 20 : 0">
							<var-col :span="formData.authorization_type === 'password' ? 12 : 24">
								<var-input
									placeholder="Username"
									v-model="formData.username"
								/>
							</var-col>

							<var-col v-if="formData.authorization_type === 'password'" :span="12">
								<var-input
									type="password"
									placeholder="Password"
									v-model="formData.password"
								/>
							</var-col>
						</var-row>

						<var-input
							v-if="formData.authorization_type === 'ssh_key'"
							placeholder="SSH key"
							textarea
							readonly
							v-model="project.ssh_key_pub"
							v-on:click="copyToClipboard"
						/>

						<var-row :gutter="20">
							<var-col :span="14">
								<var-input
									placeholder="Deployment path"
									:rules="[v => !!v || 'Deployment path cannot be empty']"
									v-model="formData.path"
								/>
							</var-col>

							<var-col :span="10">
								<var-select
									v-if="branches"
									placeholder="Default branch"
									:rules="[v => v.length >= 1 || 'You should pick a default branch']"
									v-model="formData.default_branch"
								>
									<var-option v-for="branch in branches" :label="branch.name" :value="branch.name" />
								</var-select>
							</var-col>
						</var-row>
					</var-space>
				</var-form>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<script>

import {reactive, ref} from "vue";
import request from "../request";
import { Snackbar } from '@varlet/ui'
import statics from "../static";

export default {
	name: "CreateServerModal",
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	watch: {
		show(newVal, oldVal) {
			if (newVal && !this.branches) {
				this.getBranches()
			}
		}
	},
	setup() {
		const formData = reactive({
			name: null,
			host_name: null,
			username: null,
			password: null,
			port: '22',
			authorization_type: null,
			path: null,
			default_branch: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			show: false,
			loading: false,
			loadingBranches: false,
			branches: null,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.form.validate().then(validated => {
						if (!validated) {
							return
						}

						this.loading = true

						request.post(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/servers', this.formData)
							.then(data => {
								this.loading = false
								done()
								this.$router.push({
									name: 'ServerDetails',
									params: {
										project_id: this.project.id,
										server_id: data.data.id
									}
								})
							})
							.catch(error => {
								this.loading = false

								if (error.response?.data?.message) {
									Snackbar.error(error.response.data.message)
								} else {
									Snackbar.error(statics.server_error)
								}
							})
					})
				} else {
					done()
				}
			},
		}
	},
	methods: {
		getBranches() {
			this.loadingBranches = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/branches')
				.then(data => {
					this.loadingBranches = false
					this.branches = data
				})
				.catch(error => {
					this.loadingBranches = false
					console.log(error)
				})
		},
		async copyToClipboard() {
			try {
				await navigator.clipboard.writeText(this.project.ssh_key_pub)
				Snackbar.success("Copied to clipboard")
			} catch ($e) {
				console.log($e)
			}
		}
	}
}
</script>

<style scoped>

</style>
<template>
	<data-table
		:data-source="dataSource"
		:rpp="25"
		:header-mapping="headerMapping"
		:select-at-row="selectAtRow"
		:action-button-disabled="!project.has_servers"
		action-button-disabled-text="You cannot deploy the project without adding a server to it first"
		action-button-name="Deploy"
		@onActionClick="createDeployment" />
</template>

<script>
import DataTable from "./DataTable";
import request from "../request";
import ActionableDataTable from "./ActionableDataTable.vue";

export default {
	name: "DeploymentTab",
	components: {ActionableDataTable, DataTable},
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/deployments?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			headerMapping: {
				server: 'Server',
				branch: 'Branch',
				start_commit: 'Start Commit',
				end_commit: 'End Commit',
				status: {
					label: 'Status',
					chip: true,
					type: value => {
						switch (value) {
							case 'Created':
								return 'primary'
							case 'Running':
								return 'info'
							case 'Finished':
								return 'success'
							case 'Failed':
								return 'danger'
							default:
								return 'default'
						}
					}
				},
				deployed: "Deployed"
			},
			selectAtRow: id => {
				this.$router.push({
					name: 'DeploymentDetails',
					params: {
						project_id: this.project.id,
						deployment_id: id
					}
				})
			}
		}
	},
	methods: {
		createDeployment() {
			this.$router.push({
				name: 'CreateDeployment',
				params: {
					id: this.project.id
				}
			})
		}
	}
}
</script>

<style scoped>

</style>
<template>
	<div class="var-elevation--2">
		<var-tabs v-model:active="activeTab" :scrollable="'always'">
			<var-tab name="deployments">
				Deployments
			</var-tab>
			<var-tab name="servers"
			         :disabled="!project.has_repository"
			         :title="!project.has_repository ? 'You cannot add a server without connecting a repository to the project first.' : ''">
				Servers
			</var-tab>
			<var-tab name="ssh_commands" :disabled="!project.has_servers">SSH Commands</var-tab>
			<var-tab name="repository">Repository</var-tab>
		</var-tabs>

		<var-tabs-items v-model:active="activeTab" :can-swipe="false">
			<var-tab-item name="deployments">
				<deployment-tab :project="project" />
			</var-tab-item>
			<var-tab-item name="servers">
				<server-tab
					:project-id="project.id"
					@onCreateClick="$refs.createServerModal.show = true"
					@onAddClick="$refs.addServerModal.show = true"
				/>
			</var-tab-item>
			<var-tab-item name="ssh_commands">
				<s-s-h-command-tab
					ref="sshCommandTab"
					:project-id="project.id"
					@onCreateClick="onCommandCreateClick"
					@onCommandEditClick="onCommandEditClick"
					@onCommandDeleteClick="onCommandDeleteClick"
				/>
			</var-tab-item>
			<var-tab-item name="repository">
				<repository-tab
					ref="repositoryTab"
					:project-id="project.id"
					@onButtonClick="$refs.addRepositoryModal.show = true" />
			</var-tab-item>
		</var-tabs-items>
	</div>

	<create-server-modal ref="createServerModal" :project="project" />

	<add-server-modal ref="addServerModal" :project="project" />

	<create-command-modal
		ref="createCommandModal"
		:project="project"
		@commandCreated="onCommandCreated"
	/>

	<update-command-modal ref="updateCommandModal" :project="project" />

	<delete-command-modal ref="deleteCommandModal" :project="project" @onDeleteCommand="onDeleteCommand" />

	<add-repository-modal
		ref="addRepositoryModal"
		:project="project"
		@addedRepository="onAddedRepository"
	/>

</template>

<script>
import DeploymentTab from "./DeploymentTab";
import ServerTab from "./ServerTab";
import SSHCommandTab from "./SSHCommandTab";
import RepositoryTab from "./RepositoryTab";
import request from "../request";
import CreateServerModal from "./CreateServerModal";
import CreateCommandModal from "./CreateCommandModal";
import UpdateCommandModal from "./UpdateCommandModal";
import DeleteCommandModal from "./DeleteCommandModal";
import AddRepositoryModal from "@/components/AddRepositoryModal";
import AddServerModal from "./AddServerModal.vue";
export default {
	name: "ProjectTabs",
	components: {
		AddServerModal,
		AddRepositoryModal,
		DeleteCommandModal,
		UpdateCommandModal,
		CreateCommandModal,
		CreateServerModal,
		RepositoryTab,
		SSHCommandTab,
		ServerTab,
		DeploymentTab
	},
	emits: ['addedRepository'],
	props: {
		project: {
			type: Object,
			required: true
		},
		tab: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			activeTab: this.getActiveTab(),
			repository: null,
			loading: true
		}
	},
	methods: {
		getActiveTab() {
			if (this.tab) {
				return this.tab
			}

			return 'deployments'
		},
		onCommandCreateClick(executionState) {
			this.$refs.createCommandModal.show = true
			this.$refs.createCommandModal.executionState = executionState
		},
		onCommandEditClick(id) {
			this.$refs.updateCommandModal.id = id
			this.$refs.updateCommandModal.show = true
		},
		onCommandDeleteClick(id) {
			this.$refs.deleteCommandModal.id = id
			this.$refs.deleteCommandModal.show = true
		},
		onAddedRepository() {
			this.$refs.repositoryTab.getData()
			this.$emit('addedRepository')
		},
		onCommandCreated() {
			this.$refs.sshCommandTab.refresh()
		},
		onDeleteCommand() {
			this.$refs.sshCommandTab.getCommands()
		}
	}
}
</script>

<style scoped>

</style>
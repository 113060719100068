<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="show"
			confirm-button-text="Create"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Add a repository
			</template>

			<var-skeleton :rows="7" :loading="loading">
				<var-select
					v-if="accounts"
					placeholder="Please select an account"
					v-model="accountId"
					@change="getRepositories">
					<var-option v-for="account in accounts" :label="account.login" :value="account.id" />
				</var-select>

				<var-select v-if="repositories && !loading" placeholder="Please select a repository" v-model="repositoryId">
					<var-option v-for="repository in repositories" :label="repository.full_name" :value="repository.full_name" />
				</var-select>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<script>
import request from "@/request";

export default {
	name: "AddRepositoryModal",
	emits: ['addedRepository'],
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	watch: {
		show(newVal, oldVal) {
			if (newVal && !this.accounts) {
				this.getAccounts()
			}
		}
	},
	data() {
		return {
			show: false,
			loading: false,
			accounts: null,
			repositories: null,
			accountId: null,
			repositoryId: null,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					if (this.accountId && this.repositoryId) {
						this.loading = true

						request.post(process.env.VUE_APP_BASE_URL + '/accounts/' + this.accountId + '/repositories', {
							project_id: this.project.id,
							name: this.repositoryId
						})
							.then(data => {
								this.loading = false
								this.$emit('addedRepository')
								done()
								this.activeTab = 'servers'
							})
					}
				} else {
					done()
				}
			}
		}
	},
	methods: {
		getAccounts() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + '/vcs/get_accounts')
				.then(data => {
					this.loading = false
					this.accounts = data
					this.showModal = true
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		getRepositories() {
			this.loading = true
			this.repositoryId = null
			request.get(process.env.VUE_APP_BASE_URL + '/accounts/' + this.accountId + '/get_repositories')
				.then(data => {
					this.loading = false
					this.repositories = data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>
<template>
	<div v-if="project">
		<breadcrumbs :current-item-title="project.name" :items="breadcrumbItems" />
		<page-header :name="project.name" />
		<project-tabs
			v-if="project"
			:project="project"
			:tab="$route.params.tab"
			@addedRepository="getProjectData"
		/>
	</div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import DataTable from "../components/DataTable";
import request from "../request";
import ProjectTabs from "../components/ProjectTabs";
import Breadcrumbs from "../components/Breadcrumbs.vue";
export default {
	name: "ProjectDetails",
	components: {Breadcrumbs, ProjectTabs, DataTable, PageHeader},
	created() {
		this.getProjectData()
	},
	data() {
		return {
			project: null,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			]
		}
	},
	methods: {
		getProjectData() {
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.id)
				.then(data => {
					this.project = data.data
				})
				.catch(error => {
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>
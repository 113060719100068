<template>
	<div class="var-elevation--2 p-3 mb-3 command-box">
		<div class="d-flex justify-content-between align-content-center">
			<h4 class="align-self-center m-0">{{ name }}</h4>
			<div class="d-flex justify-content-center align-items-center gap-3">
				<h5 class="m-0">{{ servers }}</h5>
				<var-button @click="$emit('onEditClick')" type="info" round>
					<var-icon name="wrench" />
				</var-button>
				<var-button @click="$emit('onDeleteClick')" type="danger" round>
					<var-icon name="window-close" />
				</var-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CommandCell",
	emits: ['onEditClick', 'onDeleteClick'],
	props: {
		name: {
			type: String,
			required: true
		},
		servers: {
			type: String,
			required: true
		}
	},
	data() {
		return {
		}
	}
}
</script>

<style scoped>

.command-box {
	cursor: pointer;
}

</style>
<script setup>

import {reactive, ref, watch} from "vue"
import request from "../request"
import {useRouter} from "vue-router"
import {Snackbar} from "@varlet/ui"
import statics from "../static";

const router = useRouter()

const props = defineProps({
	project: {
		type: Object,
		required: true
	}
})

const formData = reactive({
	server_id: null,
	path: null,
	default_branch: null
})

const form = ref(null)

const show = ref(false)
const loading = ref(false)
const loadingBranches = ref(false)
const loadingServers = ref(false)
const branches = ref(null)
const servers = ref(null)
const isEmpty = ref(false)

const getBranches = () => {
	loadingBranches.value = true
	request.get(process.env.VUE_APP_BASE_URL + '/projects/' + props.project.id + '/branches')
		.then(data => {
			loadingBranches.value = false
			branches.value = data
		})
		.catch(error => {
			loadingBranches.value = false
			console.log(error)
		})
}

const getServers = () => {
	loading.value = true
	request.get(process.env.VUE_APP_BASE_URL + '/servers/all')
		.then(data => {
			loading.value = false

			isEmpty.value = data.data?.length === 0
			servers.value = data.data
		})
		.catch(error => {
			loading.value = false
			console.log(error)
		})
}

const onBeforeClose = (action, done) => {
	if (action === 'confirm') {
	form.value.validate().then(validated => {
		if (!validated) {
			return
		}

		loading.value = true

		request.post(process.env.VUE_APP_BASE_URL + '/projects/' + props.project.id + '/servers/add', formData)
			.then(data => {
				loading.value = false
				done()
				router.push({
					name: 'ServerDetails',
					params: {
						project_id: props.project.id,
						server_id: data.data.id
					}
				})
			})
			.catch(error => {
				this.loading = false

				if (error.response?.data?.message) {
					Snackbar.error(error.response.data.message)
				} else {
					Snackbar.error(statics.server_error)
				}
			})
	})
	} else {
		done()
	}
}

const copyToClipboard = async () => {
	try {
		await navigator.clipboard.writeText(props.project.ssh_key_pub)
		Snackbar.success("Copied to clipboard")
	} catch ($e) {
		console.log($e)
	}
}

watch(show, async (newVal, oldVal) => {
	if (newVal) {
		if (!branches.value) {
			getBranches()
		}

		if (!servers.value) {
			getServers()
		}
	}
})

defineExpose({
	show
})

</script>

<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px', '--dialog-message-line-height': '22px', '--card-background': 'var(--color-body)' }">
		<var-dialog
			v-model:show="show"
			:confirm-button="!isEmpty"
			confirm-button-text="Create"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Add Server
			</template>

			<var-skeleton :rows="7" v-if="!isEmpty" :loading="loading">
				<var-form ref="form">
					<var-space direction="column" :size="[10, 0]">
						<var-row :gutter="20">
							<var-col :span="24">
								<var-loading :loading="loadingServers">
									<var-select
										placeholder="Server"
										:rules="[v => !!v || 'You should pick an existing server']"
										v-model="formData.server_id"
									>
										<var-option
											v-for="server in servers"
											:label="`${server.name} (${server.hostname})`"
											:value="server.id"
										/>
									</var-select>
								</var-loading>
							</var-col>
						</var-row>

						<var-row v-if="formData.server_id" :gutter="20">
							<var-col :span="24">
								<var-input
									placeholder="SSH key"
									textarea
									readonly
									v-model="props.project.ssh_key_pub"
									v-on:click="copyToClipboard"
								/>
							</var-col>
						</var-row>

						<var-row :gutter="20">
							<var-col :span="14">
								<var-input
									placeholder="Deployment path"
									:rules="[v => !!v || 'Deployment path cannot be empty']"
									v-model="formData.path"
								/>
							</var-col>

							<var-col :span="10">
								<var-loading :loading="loadingBranches">
								<var-select
									placeholder="Default branch"
									:rules="[v => v.length >= 1 || 'You should pick a default branch']"
									v-model="formData.default_branch"
								>
									<var-option v-for="branch in branches" :label="branch.name" :value="branch.name" />
								</var-select>
								</var-loading>
							</var-col>
						</var-row>
					</var-space>
				</var-form>
			</var-skeleton>
			<var-card v-else
		          title="No servers"
		          subtitle="You have no servers to display."
		          layout="column"
		          class="text-center"
		          :elevation="0"
			/>
		</var-dialog>
	</var-style-provider>
</template>

<style scoped>

</style>
<template>
	<var-skeleton :loading="loading">
		<div v-if="repository" class="repository-content p-4">
			<var-space direction="column" size="large">
				<var-input
					placeholder="Connected repository"
					readonly
					v-model="repository.name"
					@click="copyToClipboard(repository.name)"
				/>
				<var-input
					placeholder="Repository URL"
					readonly
					v-model="repository.url"
					@click="copyToClipboard(repository.url)"
				/>
				<var-input
					placeholder="Account"
					readonly
					v-model="repository.login"
					@click="copyToClipboard(repository.login)"
				/>
			</var-space>
		</div>
		<div v-else class="p-4">
			<var-cell>It seems you don't have a repository connected to this project</var-cell>
			<var-button type="primary" @click="$emit('onButtonClick')">Add one</var-button>
		</div>
	</var-skeleton>
</template>

<script>
import request from "../request"
import {Snackbar} from "@varlet/ui";

export default {
	name: "RepositoryTab",
	props: {
		projectId: {
			type: Number,
			required: true
		}
	},
	emits: ['onButtonClick'],
	created() {
		this.getData()
	},
	data() {
		return {
			repository: null,
			loading: true
		}
	},
	methods: {
		getData() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.projectId + '/repository')
				.then(data => {
					this.loading = false
					this.repository = data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		async copyToClipboard(text) {
			try {
				await navigator.clipboard.writeText(text)
				Snackbar.success("Copied to clipboard")
			} catch ($e) {
				console.log($e)
			}
		}
	}
}
</script>

<style scoped>

.repository-content {
	background-color: var(--table-background);
}

</style>